import "./styles.css";

const BlockItem = (props) => {

    return (<>
        <div className="block-item">
            <img src={ props.icon }/>
            <strong>{ props.title }</strong>
            <p>{ props.description }</p>
        </div>
    </>);

}

export default BlockItem;