import ProgrammingIcon from "../images/programming.png";
import ApiIcon from "../images/api.png";
import UiDesignIcon from "../images/ui-design.png";
import MercadoPagoIcon from "../images/mercadopago.png";
import SMTPIcon from "../images/smtp.png";
import FirewallIcon from "../images/firewall.png";
import HackerIcon from "../images/hacker.png";
import VulnerabilityIcon from "../images/vulnerability.png";
import SecurityIcon from "../images/security.png";

const Servicios = [
    {
        icon: ProgrammingIcon,
        title: "WebApps",
        description: "Desarrollamos aplicaciones web robustas, escalables y eficientes, adaptadas a tus necesidades, ya sea un sistema de gestión personalizado, una plataforma de eCommerce, o un portal interactivo que impulse tu negocio.",
    },
    {
        icon: ApiIcon,
        title: "APIs",
        description: "Las APIs conectan tus sistemas eficientemente, ampliando funcionalidades, mejorando la experiencia del usuario, y agilizando la comunicación entre plataformas, lo que impulsa la integración y el crecimiento de tu aplicación.",
    },
    {
        icon: UiDesignIcon,
        title: "PoC",
        description: "PoC (Proof of Concept) permite validar ideas de manera rápida antes de un desarrollo completo. Minimiza riesgos y optimiza recursos, asegurando que el proyecto avance en la dirección correcta desde sus primeras fases.",
    },
    {
        icon: MercadoPagoIcon,
        title: "MercadoPago",
        description: "Integra MercadoPago en tu webapp para aceptar pagos de manera fácil y segura, ofreciendo múltiples opciones de cobro, gestionando transacciones automáticamente y mejorando la experiencia de tus clientes.",
    },
    {
        icon: SMTPIcon,
        title: "Configuración SMTP",
        description: "Configura el servicio SMTP en tu sistema para enviar correos electrónicos de manera segura y eficiente, automatizando notificaciones, optimizando la comunicación con clientes y asegurando la entrega confiable de mensajes críticos.",
    },
    {
        icon: FirewallIcon,
        title: "Cloud WAF",
        description: "Protege tus aplicaciones web y APIs contra ataques cibernéticos con nuestra avanzada solución en la nube, diseñada para ofrecer seguridad integral, rendimiento confiable, y tranquilidad para tu negocio en línea.",
    },
    {
        icon: HackerIcon,
        title: "DDoS Mitigation",
        description: "Detenemos ataques de Denegación de Servicio antes de que impacten tu negocio, asegurando continuidad operativa, estabilidad en línea y protección contra interrupciones que podrían afectar tus operaciones y reputación.",
    },
    {
        icon: VulnerabilityIcon,
        title: "Vulnerability Assessment",
        description: "Descubre las brechas de seguridad en tu empresa con nuestra evaluación de vulnerabilidades, un proceso exhaustivo para identificar y solucionar riesgos antes de que sean explotados.",
    },
    {
        icon: SecurityIcon,
        title: "Pentesting",
        description: "El pentesting simula ataques cibernéticos para identificar y corregir vulnerabilidades en tus sistemas, asegurando que estén protegidos contra amenazas reales y manteniendo la seguridad de tu negocio.",
    },
];

export default Servicios;