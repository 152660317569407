import BotAssistant from "../../components/bot-assistant/BotAssistant";
import Section from "../../components/section/Section";
import Navbar from "../../components/navbar/Navbar";
import Row from "../../components/row/Row";
import BlockItem from "../../components/block-item/BlockItem";
import Servicios from "../../services/Servicios";
import Title from "../../components/title/Title";
import EmailIcon from "../../images/email.png";
import QRContactIcon from "../../images/qr-contact.png";

const MainIndex = () => {

	return (<>
		<Navbar />
		{/* <BotAssistant
			botName={"Ygna"}
			preloader="Pensando..."
			initMessages={[
				"¡Hola! Soy :botName, tu asistente virtual.",
				"¿En qué puedo ayudarte?",
			]}
		/> */}
		<Section dotted>
			<Row spaceBetween itemResponsive padResponsive>
				<div className="info flex-lg">
					Desarrollo de aplicaciones a medida y soluciones avanzadas de ciberseguridad para proteger y potenciar tu negocio.
				</div>
				<div className="tag flex-lg">
					<div className="tag-title">Software Dev & Cybersecurity</div>
					<div className="tag-subtitle">Argentina</div>
				</div>
			</Row>
		</Section>
		<Section>
			<Title>Servicios Informáticos</Title>
			<Row wrap>
				{ Servicios.map(s => <>
					<BlockItem
						icon={s.icon}
						title={s.title}
						description={s.description}
					/>
				</>) }
			</Row>
		</Section>
		<Section separator>
			<div style={{display: "flex"}}>
				<img
					className="qr-contact"
					src={QRContactIcon}
					style={{width: 120, height: "intrinsic", marginRight: 35, cursor: "pointer"}}
					onClick={e => window.location.href="mailto:consultas@ygna-cybersek.com"}
				/>
				<div>
					<strong style={{color: "#8a2be2"}}>Conversemos sobre tu proyecto</strong><br />
					<p>¿Tenés alguna pregunta? Estamos aquí para ayudarte. Envíanos un correo a <a href="mailto:consultas@ygna-cybersek.com" style={{wordBreak: "keep-all", color: "#8a2be2", textDecoration: "none"}}>consultas@ygna-cybersek.com</a>, y te responderemos a la brevedad.</p>
					<img
						src={EmailIcon}
						style={{width: 40, height: "intrinsic", cursor: "pointer"}}
						onClick={e => window.location.href="mailto:consultas@ygna-cybersek.com"}
					/>
				</div>
			</div>
		</Section>
		<Section style={{backgroundColor: "#222", color: "#fff", textAlign: "center"}}>
			<div>Copyright { new Date().getFullYear() } &copy; YgnaCybersek Argentina</div>
		</Section>
	</>);
}

export default MainIndex;