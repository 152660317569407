import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainIndex from './pages/web/MainIndex';
import Maintenance from './pages/web/Maintenance';

function App() {
	return (
		<Router>
			<Routes>

				{/* <Route path="/" element={<Maintenance />} /> */}
				<Route path="/" element={<MainIndex />} />

			</Routes>
		</Router>
	);
}

export default App;