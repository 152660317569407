import { useEffect, useState } from "react";
import ArrowSendImg from "../../images/arrow-send.png";
import BoyImg from "../../images/boy.png";
import "./styles.css";

const BotAssistant = (props) => {

	const BOT_STATUS_SYNCING = "syncing";
	const BOT_STATUS_ONLINE = "online";
	const BOT_STATUS_ERROR = "error";

	const CHAT_HIDDEN = "hidden";
	const CHAT_SHOWN = "shown";

	const MSG_FROM_BOT = "bot";
	const MSG_FROM_USER = "user";

	const [botStatus, setBotStatus] = useState(BOT_STATUS_SYNCING);
	const [chatStatus, setChatStatus] = useState(CHAT_HIDDEN);
	const [preloader, setPreloader] = useState(false);

	const [messages, setMessages] = useState([]);
	const [message, setMessage] = useState("");

	const toggleChat = () => {
		if (chatStatus === CHAT_HIDDEN) {
			setChatStatus(CHAT_SHOWN);
		}
	}

	const analyzeMessage = (text = "") => {
		return false;
	}

	const sendMessage = (e = null, text = "", from = MSG_FROM_BOT) => {

		if (e !== null && e.keyCode === 27) {
			setChatStatus(CHAT_HIDDEN);
			return false;
		}

		if (e !== null && e.keyCode !== 13) {
			return false;
		}

		if (from === MSG_FROM_BOT) {
			text = text.replace(":botName", props.botName);
		}

		setMessages(msg => [<div className={`bot-message bot-message-from-${from}`}>{text}</div>, ...msg]);
		setMessage("");

		if (from === MSG_FROM_USER)
		{
			setPreloader(true);

			setTimeout(() => {
				let response = analyzeMessage(text);
				if (response !== false) {
					setMessages(msg => [<div className={`bot-message bot-message-from-${MSG_FROM_BOT}`}>{response}</div>, ...msg]);
				}
				setPreloader(false);
			}, 1000);
		}
	}

	useEffect(() => {

		const handleOutsideClick = (e) => {
			const botChat = document.querySelector('.bot-chat');
			if (e.target !== botChat && !botChat.contains(e.target)) {
				setChatStatus(CHAT_HIDDEN);
			}
		};

		document.addEventListener('click', handleOutsideClick);

		let tmr = setTimeout(() => {

			setBotStatus(BOT_STATUS_ONLINE);

			props.initMessages.forEach(msg => {
				sendMessage(null, msg);
			});

			if (props.initMessages) {
				setChatStatus(CHAT_SHOWN);
			}

		}, 1000);

		return () => {
			clearTimeout(tmr)
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	return (<>
		<div className="bot-chat" onClick={toggleChat} style={{backgroundImage: `url(${BoyImg})`}}>
			<div className={`bot-status bot-status-${botStatus}`}></div>
			<div className={`bot-messages bot-messages-${chatStatus}`}>
				<div className="bot-history">
					<div className={`bot-preloader ${preloader ? "active" : ""}`}>
						{ props.preloader ?? "Procesando..." }
					</div>
					{messages}
				</div>
				<div className="bot-prompt">
					<input
						type="text"
						onKeyDown={e => sendMessage(e, message, MSG_FROM_USER)}
						onChange={e => setMessage(e.target.value)}
						value={message}
					/>
					<div
						onClick={e => sendMessage(null, message, MSG_FROM_USER)}
						className="bot-send"
						style={{ backgroundImage: `url(${ArrowSendImg})` }}
					></div>
				</div>
			</div>
		</div>
	</>);

}

export default BotAssistant;