import "./styles.css";

const Title = (props) => {

    return (<>
        <h1 className="title">{ props.children }</h1>
        <div className="title-separator"></div>
    </>);

}

export default Title;