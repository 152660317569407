import Logo from "../logo/Logo";
import "./styles.css";

const Navbar = (props) => {

    return (<>
        <nav>
            <Logo size="sm" />
            <ul>
                <li>Desarrollo</li>
                <li>Cibersegursidad</li>
                <li onClick={e => window.location.href="mailto:consultas@ygna-cybersek.com"}>Contacto</li>
            </ul>
        </nav>
    </>);

}

export default Navbar;