import "./styles.css";

const Section = (props) => {

    return (<>
        {props.separator ? <div className={`separator ${props.showLg ? "show-lg" : ""}`}></div> : ""}
        <div className={`section ${props.dotted ? "bg-dotted" : ""} ${props.showLg ? "show-lg" : ""}`} style={props.style ?? null}>{ props.children }</div>
    </>);

}

export default Section;