import LogoImg from "../../images/logo.png";
import "./styles.css";

const Logo = (props) => {

	return (<>
		<img src={LogoImg} className={`logo logo-${props.size ?? "sm"}`} />
		{props.full ? <>
			<p>Software Dev & Cybersecurity</p>
			<p>Argentina</p>
		</> : null}
	</>);

}

export default Logo;