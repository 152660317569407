import "./styles.css";

const Row = (props) => {

    return (<>
        <div className={`row ${props.wrap ? "wrap" : ""} ${props.spaceBetween ? "space-between" : ""} ${props.padResponsive ? "pad-responsive" : ""} ${props.itemResponsive ? "item-responsive" : ""}`}>{ props.children }</div>
    </>);

}

export default Row;